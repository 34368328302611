<template>
  <div>
    <b-card>
      <b-card-header
        style="padding-top: 0px; padding-right: 0px; padding-left: 0px"
      >
        <div class="d-flex align-items-center">
          <b-card-title>
            <h4><b style="color: cornflowerblue">Edit Data Peserta</b></h4>
          </b-card-title>
        </div>
        <b-nav>
          <b-nav-item>
            <router-link to="/admin/student">
              <b-button variant="primary">
                <feather-icon icon="ArrowLeftIcon" style="color: white" />
                <span class="align-right"
                  ><b style="color: white"> Kembali</b></span
                >
              </b-button>
            </router-link>
          </b-nav-item>
        </b-nav>
      </b-card-header>
      <b-form
        method="post"
        enctype="multipart/form-data"
        @submit.prevent="updateData(student)"
      >
        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default"><b>Nama</b></label>
            <b-form-input id="nama" v-model="student.nama" placeholder="Nama" />
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default"><b>Kampus</b></label>
            <b-form-input
              id="kampus"
              v-model="student.kampus"
              placeholder="Kampus"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default"><b>Jurusan</b></label>
            <b-form-select
              id="jurusan"
              v-model="student.jurusan"
              placeholder="Jurusan"
              :options="option_jurusan"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default"><b>Jenjang</b></label>
            <b-form-select
              id="jenjang"
              v-model="student.jenjang"
              placeholder="Jenjang"
              :options="option_jenjang"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default"><b>Telepon</b></label>
            <b-form-input
              id="telepon"
              v-model="student.telepon"
              placeholder="Telepon"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default"><b>Level</b></label>
            <b-form-group>
              <b-form-select
                v-model="student.level"
                label="level"
                :options="option_level"
              />
            </b-form-group>
          </b-col>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default"><b>Status</b></label>
            <b-form-group>
              <b-form-select
                v-model="student.status"
                label="status"
                :options="option_status"
              />
            </b-form-group>
          </b-col>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default"
              ><b>WARNING KHUSUS ADMINISTRATOR LEVEL</b></label
            >
            <b-form-group>
              <b-form-select
                v-model="student.level"
                label="level"
                :options="option_level2"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6" xl="4" class="mb-1" />
          <b-col md="6" xl="2" class="mb-1">
            <b-button variant="success" style="width: 100%" type="submit">
              <feather-icon icon="CheckCircleIcon" style="color: white" />
              <span class="align-middle"
                ><b style="color: white"> Simpan</b></span
              >
            </b-button>
          </b-col>
          <b-col md="6" xl="2" class="mb-1">
            <b-button
              variant="danger"
              style="width: 100%"
              type="button"
              @click="cancelButton('info')"
            >
              <feather-icon icon="XCircleIcon" style="color: white" />
              <span class="align-middle"
                ><b style="color: white"> Batal</b></span
              >
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import {
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BTfoot,
  BCard,
  BButton,
  BForm,
  BToast,
  BCardHeader,
  BNav,
  BCardTitle,
  BFormSelect,
  BNavItem,
  BFormInput,
  BRow,
  BCol,
  BFormGroup,
  BFormTextarea,
} from "bootstrap-vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Swal from "sweetalert2";
export default {
  components: {
    BCard,
    BCardTitle,
    BTableSimple,
    BThead,
    BTr,
    BForm,
    BTh,
    BToast,
    BTd,
    ToastificationContent,
    BTbody,
    Swal,
    BTfoot,
    BButton,
    BCardHeader,
    BNav,
    BFormTextarea,
    BNavItem,
    BFormInput,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    vSelect,
  },
  data() {
    return {
      student: [],
      id: this.$route.params.id,
      email: "",
      password: "",
      nama: "",
      kampus: "",
      jurusan: "",
      jenjang: "",
      telepon: "",
      status: "",
      level: "",
      option_jenjang: [
        { text: "Profesi", value: "Profesi" },
        { text: "Diploma 1", value: "D1" },
        { text: "Diploma 2", value: "D2" },
        { text: "Diploma 3", value: "D3" },
        { text: "Diploma 4", value: "D4" },
        { text: "S1", value: "S1" },
        { text: "S2", value: "S2" },
        { text: "S3", value: "S3" },
      ],
      option_level: [
        { value: "0", text: "0 - Level Free" },
        { value: "1", text: "1 - Level Premium" },
      ],
      option_level2: [
        { value: "0", text: "0 - Level Free" },
        { value: "1", text: "1 - Level Premium" },
        { value: "2", text: "2 - Level Admin" },
      ],
      option_status: [
        { value: "aktif", text: "Aktif" },
        { value: "nonaktif", text: "Tidak Aktif" },
      ],
      option_jurusan: [
        { value: "Kebidanan", text: "Kebidanan" },
        { value: "Keperawatan", text: "Keperawatan" },
        { value: "Farmasi", text: "Farmasi" },
        { value: "Teknik Gigi", text: "Teknik Gigi" },
        { value: "Teknik Elektromedik", text: "Teknik Elektromedik" },
        { value: "Gizi", text: "Gizi" },
        {
          value: "Teknologi Laboratorium Medis",
          text: "Teknologi Laboratorium Medis",
        },
        { value: "Radiologi", text: "Radiologi" },
        { value: "Keperawatan Anestiologi", text: "Keperawatan Anestiologi" },
        { value: "Terapi Gigi", text: "Terapi Gigi" },
        {
          value: "Rekam Medis dan Informasi Kesehatan",
          text: "Rekam Medis dan Informasi Kesehatan",
        },
        { value: "Terapi Okupasi", text: "Terapi Okupasi" },
        { value: "Terapi Wicara", text: "Terapi Wicara" },
        { value: "Sanitasi Lingkungan", text: "Sanitasi Lingkungan" },
        { value: "Kesehatan Gigi", text: "Kesehatan Gigi" },
        { value: "Optometri", text: "Optometri" },
        { value: "Akupunktur", text: "Akupunktur" },
        { value: "Ortotikprotestik", text: "Ortotikprotestik" },
        { value: "Fisioterapi", text: "Fisioterapi" },
      ],
    };
  },
  async created() {
    await this.$http
      .get(
        process.env.VUE_APP_BACKEND_URL +
          `users/search/${this.$route.params.id}`
      )
      .then((response) => {
        this.student = response.data.data;
        // console.log("cek test", this.student);
      });
  },
  methods: {
    async updateData(student) {
      await Swal.fire({
        title: "Apa sudah selesai?",
        text: "Perubahan Data diakhiri",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, sudah selesai!",
      })
        .then((result) => {
          if (result.value) {
            this.$http
              .put(
                process.env.VUE_APP_BACKEND_URL +
                  `users/update/${this.$route.params.id}`,
                {
                  nama: (this.student.nama = student.nama),
                  email: (this.student.email = student.email),
                  password: (this.student.password = student.password),
                  kampus: (this.student.kampus = student.kampus),
                  jurusan: (this.student.jurusan = student.jurusan),
                  jenjang: (this.student.jenjang = student.jenjang),
                  telepon: (this.student.telepon = student.telepon),
                  status: (this.student.status = student.status),
                  level: (this.student.level = student.level),
                }
              )
              .then((result) => {
                console.log(result);
              });
          }
        })
        .then((result) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Notification",
                icon: "CheckCircleIcon",
                text: "Simpan Perubahan Data",
                variant: "success",
              },
            },
            {
              timeout: 5000,
            },
            {
              position: "top-right",
            }
          );
          this.$router.push({ name: "list_student" });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    cancelButton(variant = null) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "XCircleIcon",
            text: "Cancel Perubahan Data",
            variant: "info",
          },
        },
        {
          timeout: 5000,
        },
        {
          position: "top-right",
        }
      );
      this.$router.push({ name: "list_student" });
    },
  },
};
</script>
